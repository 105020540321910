<template>
    <div class="card-detail" v-if="item">
        <div class="upper">
            <img :src="require(`@/assets/img/results/${item.image}-${language}.png`)" alt="card">
            <div class="desc-container">
                <p class="name">{{ item[`title_${language}`] }}</p>

                <p class="value first">{{ item[`p1_${language}`] }}</p>
                <p class="value">{{ item[`p2_${language}`] }}</p>
            </div>
        </div>

        <div class="lower">
            <img class="design" :src="require(`@/assets/img/banners/${item.title_en}.png`)" alt="upper style">

            <div class="interest wrapper">
                <p class="label">{{ $t('interests_and_preference') }}</p>
                <p class="value">{{ item[`interests_${language}`] }}</p>
            </div>

            <div class="careers wrapper">
                <p class="label">{{ $t('sample_careers') }}</p>
                <p class="value">{{ item[`careers_${language}`] }}</p>
            </div>

            <div class="technical wrapper">
                <p class="value">{{ $t('technical_and_vocational') }}</p>
                <p class="value">{{ item[`technical_${language}`] }}</p>
            </div>

            <div class="university wrapper">
                <p class="value">{{ $t('university_academic') }}</p>
                <p class="value">{{ item[`university_${language}`] }}</p>
            </div>

            <img class="design" :src="require(`@/assets/img/banners/${item.title_en}.png`)" alt="lower style">

            <div class="traits wrapper">
                <p class="label">{{ $t('personality_traits') }}</p>
                <p class="value">{{ item[`traits_${language}`] }}</p>
            </div>

            <div class="holland wrapper">
                <p class="value">{{ $t('compared_with_holland') }}</p>
                <p class="value">{{ item[`code_${language}`] }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
    props: {
        index: {
            type: Number,
            default: 0
        }
    },

    computed: {
        ...mapFields(['language'])
    },

    data () {
        return {
            item: null,

            cards: [
                {
                title_en: "Investigative",
                image: "1",
                p1_en: `You like using your mind. You like to observe different phenomena,
                    collect data, carry out analysis, and will even actively seek out
                    the patterns and answers behind your observations. Solving difficult
                    problems, testing different methods, and finding answers give you a
                    sense of accomplishment. You enjoy learning new things and you are
                    always interested in new knowledge, as it is learning that makes you
                    feel grounded.`,
                p2_en: `You are best suited for professions that often involve analysis and
                    thinking. You can also combine these personal characteristics with
                    your other expertise and interests, or you can incorporate more
                    research and learning into your current work (activities).
                    Observing, analyzing, thinking, reasoning, and learning are your
                    strengths, which can help you overcome any difficulties you
                    encounter.`,
                interests_en: `Prefers activities that involve thinking, analyzing, logical reasoning, or scientific experiments to seek out patterns and trends.`,
                careers_en: `Engineer (specializing in software, development, programming, systems planning, information, etc.); professions in patents and research and development; researcher, professor, scientist, mathematician, specialist in science and engineering disciplines, genetic biologist, finance and market analyst, data analyst, medical scientist, physician, forensic scientist`,
                technical_en: `Engineering and Electronic Engineering, Commerce and Management`,
                university_en: `Computer Science, Engineering, Mathematics, Physics and Chemistry, Medical Health, Life Science, Finance and Economics`,
                traits_en: `Rational, objective, logical, analytical, good at problem-solving and strategic thinking, intellectual, good at reasoning and thinking, smart, rigorous, serious, distant from others, Compared with`,
                code_en: `Investigative`,
                title_cn: '研究型',
                p1_cn: `你很喜歡動腦的活動。你喜歡觀察現象、收集資料、`,
                p2_cn: `分析，甚至會主動去找出現象中的規則和答案。你喜歡解開難題，嘗試不同的方式，發現答案的時候會很有成就感。你也喜歡學習，聽到新鮮的資訊會很有興趣，學習讓你心中覺得很踏實。`,
                interests_cn: `偏向需要思考、分析、邏輯推理、科學實驗等活動，用以發現趨勢或現象。`,
                careers_cn: `工程師（含軟體、開發、程式設計、系統規劃、資訊等）、各式專利與研發人員、研究員、教授、科學家、數學家、理工學科、基因生物學、金融/市場分析、數據分析員、醫學科學、醫師、鑑識人員等 `,
                technical_cn: `電機與電子群、商業與管理群`,
                university_cn: `資訊學群、工程學群、數理化學群、醫藥衛生學群、生命科學學群、財經學群`,
                traits_cn: `理性、客觀、邏輯清楚、分析、解決策略、喜歡學習、推理思考、聰明、嚴謹、嚴肅有距離。`,
                code_cn: `I`,
                },
                {
                title_en: "Introspective",
                image: "2",
                p1_en: `You like to think about meaningful topics. You are often thinking privately about thoughts and feelings. You are curious about topics such as the philosophy of life, numerology, astrology and psychology. You are usually highly intuitive and more sensitive than others. You are easily moved, and you are a dreamer. You feel the most positive energy when you can calm your mind, settle your thoughts, and communicate with yourself.`,
                p2_en: `You are best suited for professions that value the meaning of the mind and soul. You can also combine these personal characteristics with your other expertise and interests, or you can incorporate more elements of sensitivity and meaning into your current work (activities). Having a strong intuition, valuing the meaning of things and listening to your inner self are your strengths, which can help you overcome any difficulties you encounter.`,
                interests_en: `Prefers abstract thinking, or thinking about feelings and perceptions, introspective activities or mind-conscious thinking, likes to observe inner thoughts and feelings of individuals or groups.`,
                careers_en: `Philosopher, social scientist, psychologist, counseling psychologist, religious/spirituality worker, religious services worker, numerologist, feng shui specialist, astrology specialist, physical and mental worker; artist in a broad sense (expressing through essential oils, music, rhythm, painting, hand-crafts, etc.), as well as careers focusing on the meaning of life.`,
                technical_en: `Art`,
                university_en: `Literature, History and Philosophy, Foreign Languages, Social and Psychology, Art`,
                traits_en: `Perceptual, highly intuitive, thoughtful, sensitive, contemplative, reserved, imaginative, enjoys exploring the inner self and being in solitude, has a strong self-awareness`,
                code_en: `SI, SA`,
                title_cn: '內省型',
                p1_cn: `你很喜歡思考關於意義的主題。你常常靜靜的想一些關於感受的事情，你也會好奇關於人生哲學、命理星座、心理學等等的主題。你的直覺通常很強，感受性也比較敏感，容易被感動，也喜歡幻想。當你把心靜下來、沉澱思緒、跟自己對話之後，通常是你覺得正能量最強的時候。`,
                p2_cn: `你適合朝向需要關注心靈意義的領域；或是把此項個人特色，和其他專長、興趣結合在一起；也可以在現有的工作(活動)中，融入更多感性、意義的元素；直覺強、重視意義、傾聽內在的聲音是你的強項，你可以運用此特點來克服你正面臨的困難。`,
                interests_cn: `偏向喜歡思考抽象的、感受性的、內省的或重視心靈的思考活動、喜歡觀察個人或群體的內在活動`,
                careers_cn: `哲學家、社會科學家、心理學家、心理師、宗教神靈工作者、宗教服務、命理師、風水師、星座專家、身心靈工作者等、廣義的表達性藝術工作者（精油、音樂、律動、繪畫、手作…等），以及關注人生意義的職業。`,
                technical_cn: `藝術群`,
                university_cn: `文史哲學群、外語學群、社會心理學群、藝術群`,
                traits_cn: `感性、直覺強、省思意義、敏感、沉思、內斂、想像力豐富、探索內在、獨處、孤芳自賞。`,
                code_cn: `SI   SA`,
                },
                {
                title_en: "Creative",
                image: "3",
                p1_en: `You admire beauty and you like to appreciate things of beauty. Coordination, colors, arrangement and other aesthetics in life are important to you. You often see details that others overlook, and you are easily attracted to anything unique, which is why you often lose interest in over-repetitive activities. Your daily activities are full of artistic, creative or performance-related activities.`,
                p2_en: `You are best suited for professions that involve art or creativity. You can also combine these personal characteristics with your other expertise and interests, or you can create a more aesthetic, artistic and creative atmosphere within your current work (activities), or immerse yourself in more aesthetically-pleasing environments. Appreciating beauty and continuous innovation are your strengths, which can help you overcome any difficulties you encounter.`,
                interests_en: `Prefers activities that involve artistic autonomy and related to aesthetics, coordination, design and creative freedom, or art appreciation or critique activities.`,
                careers_en: `➤Visual design: Illustration, computer animation, image editing, sculpturing, hand-crafts, photography, comics, animation, etc.
        ➤Audio and musical arts: Music producer, performer, vocalist, singer, orchestra player, etc.
        ➤Performing arts: Dance, theater, street performer, magician, model, celebrity, actor, host, etc.
        ➤Creative writing: Writer, novelist, script writer, literary worker, editor, internet article writer, etc.
        ➤Media arts: Director, audio-visual media jobs (relating to the news, journalism, television, film, radio, etc.), internet and media jobs, advertising video producer, etc.
        ➤Art and design: Graphic design, advertisement and commercial design, product design, web design, interior design, fashion design, cultural and creative design, architecture and landscape design, etc.`,
                technical_en: `Art, Design, Home Economics, Civil Engineering and Architecture`,
                university_en: `Art, Mass Communication, Literature, History and Philosophy, Foreign Languages, Architecture and Design`,
                traits_en: `Rich in emotions, creative, humorous, confident, focused, artistic, romantic, aesthetic, sees the world differently, sentimental`,
                code_en: `A`,
                title_cn: '創作型',
                p1_cn: `你崇尚美感，喜歡欣賞具美感的事物。你很在意生活事物中的協調性、色調、搭配等等美感的問題。你常常會看見別人忽略的小地方，只要是有特色的東西都容易吸引你。也因此，重複性太高的活動，容易讓你失去興趣。你的日常活動有許多跟藝術、創作或表演有關的元素。`,
                p2_cn: `你適合朝向需要藝術或創作的領域；或是把此項個人特色，和其他專長、興趣結合在一起；也可以在現有的工作(活動)中，創造更多美感、藝術、創作的氛圍，或把自己放在美感較多的環境裡；感受美和持續創新是你的強項，你可以運用此特點來克服你正面臨的困難。`,
                interests_cn: `偏向喜歡具有美感、協調性、創造性、可自由創作的自主性藝術活動，並且可以欣賞與評論。`,
                careers_cn: `➤視覺創作：繪畫、電腦繪圖、圖片編修、雕刻雕塑、手工藝品創作、攝影師、漫畫、動漫等。
        ➤聽覺創作：音樂製作、演奏家、歌唱家、歌手、樂團等
        ➤表演創作：舞蹈、舞台劇、街頭藝人、魔術師、模特兒、明星、演員、主持人等
        ➤文字創作：作家、小說家、劇本創作、各式文字工作者、編輯、網路文章等
        ➤媒體創作：導演、影音媒體工作者（新聞、記者、電視、電影、廣播…等）、網路媒體工作者、廣告宣傳影片等。
        ➤設計創作：平面設計、廣告商業設計、產品設計、網頁設計、室內設計、服裝造型設計、文創設計、建築景觀設計等`,
                technical_cn: `藝術群、設計群、家政群、土木與建築群`,
                university_cn: `藝術學群、大眾傳播學群、文史哲學群、外語學群、建築與設計學群`,
                traits_cn: `情感豐富、創造性、幽默、自信、專注、藝術氣息、浪漫、美感、另類思考、多愁善感`,
                code_cn: `A`,
                },
                {
                title_en: "Compassionate",
                image: "4",
                p1_en: `You enjoy having deep interactions with people. You spend a lot of time with others, and tend to do certain activities or work toward mutual goals with others. You feel at ease and a sense of belonging when being with people. The feelings and the well-being of others are important to you. If you see someone in need, you will feel compassion and take action to help out. You feel more motivated when your efforts have an impact on others.`,
                p2_en: `You are best suited for professions that involve interacting and influencing others. You can also combine these personal characteristics with your other expertise and interests, or you can take on more roles and opportunities that involve human interaction within your current work (activities). Deep communication with others, and sensitivity and empathy towards others are your strengths, which can help you overcome any difficulties you encounter.`,
                interests_en: `Prefers activities that allow you to use your influence to help, care for, or empower others, as well as activities that require perceptive interpersonal interaction, communication and coordination skills.`,
                careers_en: `Teacher, social worker, psychologist, special education teacher, preschool teacher, babysitter, nurse, medical personnel, lawyer, talent teacher, social worker, volunteer, non-profit organization worker, social welfare organization worker, funeral director, care attendant, firefighter, police officer, tour guide, guard, search and rescue worker, sign language teacher, human resources jobs, employment specialist, recruiter, postpartum doula, lactation consultant, etc.`,
                technical_en: `Home Economics, Commerce and Management`,
                university_en: `Medical Health, Education, Social and Psychology, Management`,
                traits_en: `Passionate about helping others, kind, empathetic, warm, expressive, considerate, cares for others, friendly, has good interpersonal skills, indecisive`,
                code_en: `S, SE`,
                title_cn: '社會型',
                p1_cn: `你很喜歡與人深度的互動。你會花許多時間與他人相處，也傾向與有人一起進行某些活動或共同朝向目標努力。和人在一起讓你覺得安心、有歸屬感。你也會關切他人的感受或權益。當看見有人需要被幫助時，你會有惻隱之心，並且採取某些行動去協助他。如果你的付出能夠影響到其他人，你會覺得更有動力。`,
                p2_cn: `你適合朝向與人互動、影響他人的領域；或是把此項個人特色，和其他專長、興趣結合在一起；也可以在現有的工作(活動)中，擔任更多與人互動的角色和機會；和他人深入交流、敏感與同理心是你的強項，你可以運用此特點來克服你正面臨的困難。`,
                interests_cn: `偏向能夠運用自身的影響力，幫助或照顧他人、提升他人的活動，具敏銳的人際互動與溝通協調能力。`,
                careers_cn: `老師、社工師、心理師、特教老師、幼教老師、褓姆、護士、醫事人員、律師、才藝老師、社會工作者、義工 /志工、非營利組織、社會福利組織、禮儀師、照顧服務員、消防人員、警察、導覽人員、防護員、救難隊、手語師、人事部、就業服務員、人力仲介、月嫂、泌乳師等。`,
                technical_cn: `家政群、商業與管理群`,
                university_cn: `醫藥衛生學群、教育學群、社會心理學群、管理學群`,
                traits_cn: `熱心助人、親切、同理心、溫暖、擅表達、體貼、照顧他人、友善、人際關係好、優柔寡斷`,
                code_cn: `S SE`,
                },
                {
                title_en: "Sensory",
                image: "5",
                p1_en: `Senses of the human body are important to you. Your body is highly sensitive to sensory experiences and requires constant attention and stimulation. For example, taste enjoyment, visual satisfaction, physical activities, tactile stimulation will usually make you feel relaxed, comfortable, contented and happy. You have a strong motivation to pursue sensory satisfaction and you attach great importance to the quality and enjoyment of life.`,
                p2_en: `You are best suited for professions that involve sensory activities. You can also combine these personal characteristics with your other expertise and interests, or you can incorporate opportunities to have more environmental stimulation or sensory enjoyment into your current work (activities). Knowing how to attain emotional satisfaction through sensory satisfaction, and how to enjoy life are your strengths, which can help you overcome any difficulties you encounter.`,
                interests_en: `Prefers activities involving the senses (taste, smell, sight, sound, touch, body sensations).`,
                careers_en: `Gourmet, chef, traveler, tour guide, wine taster, bartender, driver, DJ, voice and sound effect artist, lighting technician, dubbing artist, athlete, fitness coach, beauty salon jobs, masseuse, stylist, nail artist, extreme sports athlete, adventurer, travel agency staff, housekeeper, working vacation jobs, errand and courier jobs, bridal makeup artist and hairstylist, wedding planner, work exchange, tattooist, game QA tester, user testing, purchasing broker, etc.`,
                technical_en: `Hospitality, Food Science, Home Economics, Foreign Languages, Art`,
                university_en: `Sports and Recreation, Art, Architecture and Design, Mass Communication`,
                traits_en: `Straightforward, optimistic, easy-going, energetic, generous, adventurous, free, hedonistic, lives in the moment, indulges in comfort`,
                code_en: `AR`,
                title_cn: '感官型',
                p1_cn: `你很重視身體的各種感官。你對身體的感受性比較強烈，需要時常的滿足和持續刺激，例如：重視味覺享受、視覺滿足、身體活動、觸覺刺激等等，通常會讓你覺得放鬆、舒服、滿足和愉快。甚至你會有很強的動機要追求感官的滿足，並且很重視生活的品質與享樂。`,
                p2_cn: `你適合朝向運用感官活動的領域；或是把此項個人特色，和其他專長、興趣結合在一起；也可以在現有的工作(活動)中，安排更多環境刺激或感官享受的機會；「身體感官舒服了，心情就美麗了」以及「懂得享受生活」是你的強項，你可以運用此特點來克服你正面臨的困難。`,
                interests_cn: `偏向喜歡各式感官 (味覺、嗅覺、視覺、聽覺、觸覺、身體感覺)相關的活動。`,
                careers_cn: `美食家、廚師、旅遊家、導遊、品酒調酒師、駕駛員、DJ、聲控音效師、燈光場控、配音師、運動員、健身教練、美容美髮、按摩師、造型師、美甲、極限運動、冒險家、旅行社人員、管家、打工渡假、代客跑腿、快遞人員、新娘秘書、打工換宿、刺青師、遊戲試玩、用戶體驗員、代購服滿候等。`,
                technical_cn: `餐旅群、食品群、家政群、外語群、藝術群`,
                university_cn: `運動與遊憩學群、藝術學群、建築與設計學群、大眾傳播學群`,
                traits_cn: `直率、樂觀、隨性、有活力、大方、冒險、自由、享樂、活在當下、安逸耽溺。`,
                code_cn: `AR`,
                },
                {
                title_en: "Operative",
                image: "6",
                p1_en: `You enjoy hands-on activities. You feel grounded when you work with actual tools, materials, and objects. These activities also help you to think. You absorb knowledge better and feel more enthusiastic when you engage with external elements and practical application during any learning process. You value practical matters, while things which you cannot see tend to seem impractical and unrealistic to you.`,
                p2_en: `You are best suited for professions that involve hands-on activities. You can also combine these personal characteristics with your other expertise and interests, or you can incorporate a more hands-on approach towards your current work (activities). Carrying out concrete action and staying practical are your strengths, which can help you overcome any difficulties you encounter.`,
                interests_en: `Prefers hands-on tasks with clear steps and defined rules.`,
                careers_en: `Jobs in production and manufacturing, mechanical engineering, mechanical operation, maintenance and repair, engineering (including jobs relating to testing, network management, quality control, etc.); construction worker, packer, quality control/inspector, plumber, manual laborer, porter, jobs in agriculture, forestry, fishery and animal husbandry; chiropractor, technician, carpenter, hand-crafts related jobs, ground attendant, military personnel, security guard, cleaner, housekeeping staff, logistics/warehouse management staff, marine patrol, draughtsman, art designer, etc.`,
                technical_en: `Mechanical Engineering, Power Mechanical Engineering, Chemical Engineering, Electrical Engineering and Electronic Engineering, Civil Engineering and Architecture, Agriculture, Marine Technology, Fishery, Food Science`,
                university_en: `Engineering, Management, Earth and Environment, Biology and Resources`,
                traits_en: `Pragmatic, realistic, hardworking, action-oriented, practical, persevering, rules-oriented, responsible, takes things step-by-step, assertive`,
                code_en: `R`,
                title_cn: '操作型',
                p1_cn: `你很喜歡動手操作。能夠實際地拿起工具、材料、物品來動手操作，你才會有踏實感，也是你最容易思考的時候。在學習的過程中，如果可以對應到外在事物、實際操作運用的話，你會吸收得更好，也更有熱情。你重視實際層面的人物事，對於看不見的部分容易讓你覺得空談、不踏實。`,
                p2_cn: `你適合朝向動手操作的領域；或是把此項個人特色，和其他專長、興趣結合在一起；也可以將現有的工作(活動)內容，轉換成更具體實作的方式；行動力和腳踏實地是你的強項，你可以運用此特點來克服你正面臨的困難。`,
                interests_cn: `偏向喜歡動手操作，有清楚明確的步驟或規則。`,
                careers_cn: `生產製造、機械工程、機械操作、維護修理、工程師(含測試、網管、品管等)、營造施作人員、作業包裝員、品管/檢驗人員、水電工、粗工、搬運工、農林漁牧業、整復師、技術人員、木工、手工製作、地勤人員、軍人、保全警衛、清潔員、家事人員、物流/倉管人員、海巡人員、工業製圖、美工等。`,
                technical_cn: `機械群、動力機械群、化工群、電機與電子群、土木與建築群、農業群、海事群、水產群、食品群`,
                university_cn: `工程學群、管理學群、地球環境學群、生物資源學群`,
                traits_cn: `務實、腳踏實地、耐勞、行動力、實用、毅力、重規則、負責、按部就班、擇善固執`,
                code_cn: `R`,
                },
                {
                title_en: "Conventional",
                image: "7",
                p1_en: `You like to deal with various tasks and affairs. You are good at organizing and dealing with routine matters that others find trivial. You also enjoy working with static documents such as data, forms, and reports. Organizing and handling methodical and detailed information in a systematic way gives you a sense of accomplishment. You usually have a to-do list or planning schedule in your mind, as if you are your own secretary.`,
                p2_en: `You are best suited for professions involving transaction and processing tasks. You can also combine these personal characteristics with your other expertise and interest, or you can incorporate more rules and structured ways of working into your current work (activities). Planning and doing things step-by-step are your strengths, which can help you overcome any difficulties you encounter.`,
                interests_en: `Prefers dealing with detailed matters with a clear set of rules such as data, paperwork and various tasks.`,
                careers_en: `Various types of assistants, administrative personnel, secretary, procurement personnel, clerk, task force civil servant, bookkeeper, note-taker, banker, cashier, scribe, scrivener, trademark/patent attorney, accountant, accounting clerk, auditor, tax inspector, translator, network administrator, agent officer, operator, general affairs staff, cashier clerk, financial manager, data manager, art editor, draughtsman, intern jobs, bonding personnel, revenue officer, auctioneer, appraiser,etc.`,
                technical_en: `Foreign Languages, Commerce and Management`,
                university_en: `Literature, History and Philosophy, Foreign Languages, Finance and Economics, Management, Law and Politics`,
                traits_en: `Conventional, detail-oriented, cautious, patient, persistent, organized, self-disciplined, good at planning, introvert, conservative`,
                code_en: `C`,
                title_cn: '事務型',
                p1_cn: `你很喜歡處理事務。別人覺得瑣碎的例行事務，你可以有條理的規劃和處理。你也喜歡靜態的文書、資料、表格、報表等等。能夠將瑣碎的資料，變成有系統的方式來整理，會讓你很有成就感。你心中常常有個待辦清單或規畫表，彷彿內心就是個行動小秘書。`,
                p2_cn: `你適合朝向事務處理的領域；或是把此項個人特色，和其他專長、興趣結合在一起；也可以在現有的工作(活動)中，歸納更多有規則、有系統的做事方法；規劃和逐步進行是你的強項，你可以運用此特點來克服你正面臨的困難。`,
                interests_cn: `偏向喜歡處理資料、文書、各式作業等有明確規則的細節事務`,
                careers_cn: `各式助理、行政人員、秘書、採購人員、幹事人員、專責公務員、簿記人員、速記人 員、銀行人員、收銀員、代書、地政士、商標/專利人員、會計、記帳/查帳人員、審核/稽核人員、翻譯人員、網管人員、代辦人員、總機、總務人員、出納、財務、資料管理員、美編、繪圖員、工讀生、保稅人員、稅務人員、拍賣官、鑑定師等。`,
                technical_cn: `外語群、商業與管理群`,
                university_cn: `文史哲學群、外語學群、財經學群、管理學群、法政學群`,
                traits_cn: `循規蹈矩、細心、謹慎、耐心、執著、有條理、自律、擅長規劃、內向、保守。`,
                code_cn: `C`,
                },
                {
                title_en: "Enterprising",
                image: "8",
                p1_en: `You enjoy business-related activities, which motivates and excites you. You pay attention to market trends, economic fluctuations, investment concepts, and you look up to successful entrepreneurs. You have a worldview and you are ambitious, daring to take risks and invest. You are keen to make money in smart ways, as you see income as your personal goal and as what gives you a sense of accomplishment.`,
                p2_en: `You are best suited for professions that involve business-related activities. You can also combine these personal characteristics with your other expertise and interests, or incorporate more long-term investment, planning, business activities into your current work (activities). Investing in the future and long-term planning are your strengths, which can help you overcome any difficulties you encounter.`,
                interests_en: `Prefers all kinds of business activities that meet practical needs.`,
                careers_en: `Entrepreneur, jobs related to marketing planning, advertising proposal planning, game planning, product planning, website planning, event planning, wedding planning, events planning; curator, business executive, jobs in finance and business, public relations officer, starting your own business, online marketing, e-commerce, appraiser, jobs related to financial management and risk management, financial specialist, etc.`,
                technical_en: `Commerce and Management, Design, Art, Foreign Languages`,
                university_en: `Mass Communication, Finance and Economics, Management, Foreign Languages`,
                traits_en: `Proactive, flexible, ambitious, visionary, brave to break through, good at strategies, seizes opportunities, good at integrating and planning, has a broad network of contacts, utilitarian`,
                code_en: `E`,
                title_cn: '商業型',
                p1_cn: `你很喜歡商業活動。商業活動讓你覺得充滿活力和熱情，你會關注市場趨勢、經濟脈動、投資觀念等等，對於成功的企業家投以敬佩的讚賞。你擁有世界觀和企圖心，敢於冒險和投入，很熱衷於用聰明的方法賺錢，也會以收入來當作個人目標和成就感。`,
                p2_cn: `你適合朝向商業活動的領域；或是把此項個人特色，和其他專長、興趣結合在一起；也可以在現有的工作(活動)中，增添更多長期投資、企畫活動、商業行為的部分；投資未來、從長計議是你的強項，你可以運用此特點來克服你正面臨的困難。`,
                interests_cn: `偏向需要創造符合實際層面的各式商業活動`,
                careers_cn: `企業家、行銷企劃、廣告文案、遊戲企劃、產品企劃、網站企劃、活動企劃、婚禮/節目企劃、策展人、業務主管、金融營業人員、公關經紀人、自行創業、網路行銷、電子商務、估價師、財務管理、風險管理、理財專員等。`,
                technical_cn: `商業與管理群、設計群、藝術群、外語群`,
                university_cn: `大眾傳播學群、財經學群、管理學群、外語學群`,
                traits_cn: `主動積極、靈活、企圖心、有遠見、勇於突破、擅謀略、把握時機、擅整合規劃、運用人脈、功利主義。`,
                code_cn: `E`,
                },
                {
                title_en: "Dominant",
                image: "9",
                p1_en: `You like to lead and take control. You set high standards for yourself and have expectations to make steady progress or become stronger. You often feel that you are not yet successful. You are highly ambitious, you expect to stand out, and you attach great importance to end results. You enjoy pursuing a sense of accomplishment and express yourself. In a group, you are a potential leader. You want to be leading the development of things, and you hope to be valued and noticed. You even desire to power.`,
                p2_en: `You are best suited for professions that involve leadership. You can also combine these personal characteristics with your other expertise and interests, or you can strive to incorporate more opportunities to perform, lead and make independent decisions into your current work (activities). Ambition, steady progress and organizational leadership are your strengths, which can help you overcome any difficulties you encounter.`,
                interests_en: `Prefers tasks that require leadership, such as guiding others, organizing and running groups, planning for decision-making and arbitration, and demonstrating yourself.`,
                careers_en: `Teacher, politician, executive-level civil servant, business executive, school principal, store manager, entrepreneur, manager, project manager, host, sponsor, supervisor in various professions, judge, mental coach/spiritual mentor, workplace coach, corporate consultant, reviewer/appraiser, connoisseur, superintendent, sergeant, prosecutor, pilot, captain, architect, freelancer, auctioneer, etc.`,
                technical_en: `Commerce and Management, Art, Design`,
                university_en: `Mass Communication, Management, Finance and Economics, Law and Politics, Architecture and Design, Art, Education`,
                traits_en: `Leadership, good at organizing, dominant, ambitious, confident, aggressive, decisive, charismatic, perfectionist, self-centered`,
                code_en: `ES, ESA`,
                title_cn: '主導型',
                p1_cn: `你很喜歡主導與掌控。你的自我要求很高，時常期許自己不斷地進步和變強，或是總覺得自己還不夠成功。你有強烈的好勝心，期待自己脫穎而出，並且很重視結果的好壞，樂於追求成就感和自我表現。在團體中，你可能是有潛力的領導者，你想要主導情勢發展，希望自己被重視和關注，甚至渴望能掌握權力。`,
                p2_cn: `你適合朝向主導性強的領域；或是把此項個人特色，和其他專長、興趣結合在一起；也可以在現有的工作(活動)中，爭取更多可以表現、領導、自主決定的機會；好勝、不斷進步、組織領導力是你的強項，你可以運用此特點來克服你正面臨的困難。`,
                interests_cn: `偏向需要主導情勢的工作，可能是指導領導他人、組織運作團體、規劃決策仲裁，喜歡展現自我。`,
                careers_cn: `老師、政治家、主管級公務員、企業主管/幹部、校長、店長、自行創業、經理、專案管理師、主持人、主辦人、各領域主管階層、法官、心智訓練師/心靈導師、職場教練、企業顧問、評審/評鑑、鑑賞家、督導、軍士官、檢察官、機師、船長、建築師、個人工作者、拍賣官等`,
                technical_cn: `商業與管理群、藝術群、設計群`,
                university_cn: `大眾傳播學群、管理學群、財經學群、法政學群、建築與設計學群、藝術學群、教育學群`,
                traits_cn: `領導、擅組織、強勢、好勝、有自信、積極進取、果決、有個人魅力、追求完美、自我中心。`,
                code_cn: `ES ESA`,
                },
                {
                title_en: "Sociable",
                image: "10",
                p1_en: `You like to use the power of words. You like working with others, and keen on using your communication skills. Persuading others to agree with your ideas gives you a sense of accomplishment. You are usually good at talking and networking. You are lively and outgoing, and you often proactively recommend good things to your friends, as you firmly believe that good things are for everyone. You often pursue things that can benefit others as well as yourself.`,
                p2_en: `You are best suited for professions that involve speech expression. You can also combine these personal characteristics with your other expertise and interests, or you can strive to incorporate more opportunities for self-expression and communicating with people into your current work (activities). Having a broad network of contacts, optimism and positivity are your strengths, which can help you overcome any difficulties you encounter.`,
                interests_en: `Prefers direct interaction with people to make product recommendations, or persuasive marketing and sales activities.`,
                careers_en: `Sales agent, insurance broker, investment and wealth management personnel, admissions officer, sales agent, counter store representative, real estate agent, direct sales, sales channel development jobs, business engineer, etc.`,
                technical_en: `Commerce and Management, Foreign Languages`,
                university_en: `Finance and Economics, Management, Mass Communication, Foreign Languages`,
                traits_en: `Outgoing, positive, optimistic, hospitable, talkative, good at socializing, observant, resilient, adaptable, versatile, enjoys debating`,
                code_en: `ESC`,
                title_cn: '業務型',
                p1_cn: `你很喜歡善用說話的魔力。你喜歡與他人一起活動，並且熱衷於和他人交手講話的功力，若能說服他人認同你的想法，你會很有成就感。通常你能言善道、交友廣闊、活潑外向，也會主動推薦好的東西給朋友，並且堅信好的東西就是要大家一起使用。利己又利人可能是你追求的方向。`,
                p2_cn: `你適合朝向以講話表達為主的領域；或是把此項個人特色，和其他專長、興趣結合在一起；也可以在現有的工作(活動)中，爭取更多自我表達、與人交流的機會；人脈廣闊、樂觀正向是你的強項，你可以運用此特點來克服你正面臨的困難。`,
                interests_cn: `偏向直接與人接觸，進行商品推薦、或說服的商業銷售活動`,
                careers_cn: `各式業務人員、保險經紀人、投資理財人員、招生人員、銷售人員、專櫃門市人員、不動產經紀人、傳直銷、業務通路開發人員、業務工程師等`,
                technical_cn: `商業與管理群、外語群`,
                university_cn: `財經學群、管理學群、大眾傳播學群、外語學群`,
                traits_cn: `外向、積極、樂觀、好客、健談、擅交際、察言觀色、有韌性、臨機應變、八面玲瓏、好辯。`,
                code_cn: `ESC`,
                },
                {
                title_en: "Helpful",
                image: "11",
                p1_en: `You like to give without expectations. You like being in a group, but you don't especially like the attention. You are often quietly observing the needs of others. Providing help whenever you can gives you a sense of accomplishment. You are easy-going, friendly, amicable, and focused on the big picture, but sometimes you might compromise yourself by being too accommodating to others. You are a reliable helper, a trusted right-hand man.`,
                p2_en: `You are best suited for professions that are highly involved in servicing. You can also combine these personal characteristics with your other expertise and interests, or you can take on more service-providing roles in your current work (activities), which is usually welcomed in enhancing interpersonal relationships in the workplace. Willingness to give, calmness and patience are your strengths, which can help you overcome any difficulties you encounter.`,
                interests_en: `Prefers to provide various services (mainly indirect product sales) to better benefit consumers or to meet customer needs and expectations.`,
                careers_en: `All kinds of service personnel, customer service representative, store representative, professions in leisure and tourism, food service, customer complaint handler, customer service engineer, after-sales service advisor, receptionist, housekeeper, etc.`,
                technical_en: `Commerce and Management, Hospitality, Foreign Languages`,
                university_en: `Mass Communication, Sports and Recreation`,
                traits_en: `Kind, good-tempered, enthusiastic, patient, behind-the-scenes, reliable, easy-going, amicable, self-sacrifice and dedication, has no principles`,
                code_en: `ESR`,
                title_cn: '服務型',
                p1_cn: `你很喜歡默默地付出。你喜歡在群體中，但是不一定喜歡被關注；你會默默地觀察其他人的需要，有機會就會從旁提供協助，並且感到有成就感。你很隨和、親切、合群、以大局為重，但是有時候會不小心過於遷就他人而委屈自己。你是個很可靠的小幫手或得力助手。`,
                p2_cn: `你適合朝向服務性質強的領域；或是把此項個人特色，和其他專長、興趣結合在一起；也可以在現有的工作(活動)中，擔任更多提供服務的角色和機會，這也是職場人際中受歡迎的因素之一；願意付出、沉著忍耐是你的強項，你可以運用此特點來克服你正面臨的困難。`,
                interests_cn: `偏向提供各式服務(主要非直接銷售產品)以提升消費效益或顧客的心理滿足`,
                careers_cn: `各式服務人員、客服人員、門市人員、休閒觀光、餐飲從業人員、客訴部門、客服工程師、售後服務人員、櫃台接待人員、管家等`,
                technical_cn: `商業與管理群、餐旅群、外語群`,
                university_cn: `大眾傳播學群、運動遊憩學群`,
                traits_cn: `親切、脾氣好、熱心、忍耐、居於幕後、可靠、隨和、合群、犧牲奉獻、沒原則。`,
                code_cn: `ESR`,
                },
                {
                title_en: "Natural",
                image: "12",
                p1_en: `You like the natural environment. You feel most relaxed and grounded by being close to nature, animals and plants. You attach great importance to issues such as health, environmental protection, and the global environment. You sometimes have a strong determination towards these issues and even think of them as your mission. You are sincere, honest and kind. Friends often think of you as genuine and harmless.`,
                p2_en: `You are best suited for professions that are related to health, nature, environmental protection, animals and plants. You can also combine these personal characteristics with your other expertise and interests, or you can make health, nature and environmental protection more relevant in your current work (activities). Connecting with nature, sincerity and kindness are your strengths, which can help you overcome any difficulties you encounter.`,
                interests_en: `Likes to connect with nature and pays attention to issues such as ecological conservation, environmental protection, and health.`,
                careers_en: `Ecotourism guide, non-profit organization worker, environmental conservationist, biologist, ecologist, zoologist, botanist, zoo doctor/plant doctor, national park ranger, zoo and botanical garden staff, zoo volunteer, environmental volunteer, pet groomer, astronomer, geologist, pet communicator, pet trainer, vet assistant, animal boarding jobs; professions relating to animal and plant breeding, or health and well-being; nutritionist, community builder, environmental pollution and contamination tester; professions in public health and food sanitation, etc.`,
                technical_en: `Agriculture, Fishery`,
                university_en: `Life Science, Biology and Resources, Earth and Environment`,
                traits_en: `Innocent, natural, kind, contented, has simple desires, generous, sincere, honest, compassionate, overly idealistic`,
                code_en: `SI`,
                title_cn: '自然型',
                p1_cn: `你很喜歡天然環境。接近大自然、動植物會讓你最放鬆與安定，你也很重視健康、環保、地球環境等議題，在這些方面，你偶爾會有過高的堅持，甚至認為這是自己的使命。你的個性很真誠、正直、仁慈，常常讓朋友覺得你率真且無害。`,
                p2_cn: `你適合朝向健康、自然、環保、動植物相關的領域；或是把此項個人特色，和其他專長、興趣結合在一起；也可以在現有的工作(活動)中，提高更多與健康、自然、環保的相關聯性；回歸大自然、真誠仁慈是你的強項，你可以運用此特點來克服你正面臨的困難。`,
                interests_cn: `喜歡接觸大自然、 關注生態保育、環境保護、健康等議題。`,
                careers_cn: `生態解說導覽員、非營利組織人員、環境保育倡議者、生物/態學家、動植物學家、動植物醫師、國家公園管理員、動植物園工作人員、動物保護志工、環境保護志工、寵物美容、天文學家、地質學家、寵物溝通師、訓練師、動物醫院助理、寵物住宿、動植物培育人員、健康生機相關工作、營養師、社區營造人員、環境污染測試人員、公共衛生人員、食品衛生人員等。`,
                technical_cn: `農業群、水產群`,
                university_cn: `生命科學學群、生物資源學群、地球與環境學群`,
                traits_cn: `天真、自然、善良、知足、與世無爭、慷慨、真誠、正直、仁慈、過於理想化。`,
                code_cn: `SI`,
                }
            ]
        }
    },

    mounted () {
        this.item = this.cards[this.index-1]
    },
}
</script>

<style lang="scss" scoped>
.card-detail {
    display: flex;
    flex-direction: column;
    max-width: 1200px;
    margin: auto;
    padding-top: 100px;
    text-align: center;

    @include desktop {
        text-align: left;
    }

    p {
        color: #221E1F;
        font-size: 18px;

        &.name {
            font-weight: 600;
            font-size: 36px;
            margin-bottom: 12px;
        }

        &.value {
            font-family: 'Crimson Pro', sans-serif;
        }

        &.first {
            margin-bottom: 52px;
        }

        &.label {
            font-weight: 600;
            font-size: 26px;
            margin-bottom: 27px;
        }
    }

    .upper {
        display: flex;
        margin-bottom: 40px;
        flex-direction: column;

        @include desktop {
            flex-direction: row;
        }

        img {
            width: 263px;
            height: 350px;
            margin: 0 auto 20px;

            @include desktop {
                margin-right: 50px;
            }
        }
    }

    .wrapper {
        margin-bottom: 35px;
    }

    .university {
        margin-bottom: 75px;
    }

    .design {
        width: 100%;
        margin-bottom: 30px;
    }

    .holland {
        p {
            font-weight: 600;
            margin-right: 5px;
            display: inline-block;
        }
    }
}
</style>